import { useTranslation } from 'next-i18next'
import { useState, type MouseEvent } from 'react'
import { twMerge } from 'tailwind-merge'
import { Text, HighlightableText } from '../../components/text'
import { Icon, type IconMapType } from '../../components/icon'
import safelyParseJson from '../../utils/safely-parse-json'

type CrowdinCriteriaItemType = {
  missionKind?: string
  iconType: IconMapType
  description: Record<string, string[]>
}

type CriteriaItemType = Omit<CrowdinCriteriaItemType, 'description'> & {
  descriptions: string[]
  values: Record<string, any>
}

type SuccessCriteriaProps = Partial<{
  showSkeleton: boolean
  criteriaItemValuesMap: Record<string, CriteriaItemType['values']>
  activeIndex: number
  onChange: (params: Pick<CriteriaItemType, 'missionKind'> & { index: number }) => void
}>

const SuccessCriteria = ({
  showSkeleton,
  criteriaItemValuesMap,
  activeIndex = 0,
  onChange,
}: SuccessCriteriaProps) => {
  const { t, i18n } = useTranslation()
  const [_activeIndex, _setActiveIndex] = useState(activeIndex)

  const successCriteriaItems = t(
    'crowdin:product-detail-page.tabs.moneyback.success-criteria.items',
    {
      returnObjects: true,
    },
  )

  const criteriaItems = criteriaItemValuesMap
    ? safelyParseJson<CrowdinCriteriaItemType[]>(
        t('crowdin:product-detail-page.tabs.moneyback.success-criteria.items'),
        Array.isArray(successCriteriaItems) ? successCriteriaItems : [],
      )
        .filter(({ missionKind, description }) => {
          return (
            missionKind &&
            i18n.exists(`crowdin:moneyback.mission.learnable-kinds.${missionKind}`) &&
            criteriaItemValuesMap[missionKind] &&
            Array.isArray(description[criteriaItemValuesMap[missionKind].type ?? 'default'])
          )
        })
        .map(item => {
          const newItem: CriteriaItemType = {
            missionKind: item.missionKind,
            iconType: item.iconType,
            values: {},
            descriptions: [],
          }
          if (item.missionKind) {
            newItem.values = criteriaItemValuesMap[item.missionKind]
            newItem.descriptions = item.description[newItem.values.type ?? 'default']
          }
          return newItem
        })
    : []

  const onClick =
    (params: Parameters<NonNullable<SuccessCriteriaProps['onChange']>>[0]) =>
    (_: MouseEvent<HTMLLIElement>) => {
      _setActiveIndex(params.index)
      onChange && onChange(params)
    }

  return (
    <div>
      <Text component='h5' variant='heading_m_500' className='mb-3'>
        {t('crowdin:product-detail-page.tabs.moneyback.success-criteria.title')}
      </Text>
      <ul className='m-0 mb-3 flex list-none flex-wrap items-center gap-2 p-0'>
        {criteriaItems.map(({ missionKind, iconType }, index) => {
          return (
            <li
              key={missionKind}
              className={twMerge(
                'flex w-fit cursor-pointer items-center gap-x-1 rounded-full px-3 py-1 ', // layouts
                'border-primary-500 border border-solid text-white transition-colors', // colors
                _activeIndex === index ? 'bg-primary-500' : 'text-primary-500 bg-white',
              )}
              onClick={onClick({ missionKind, index })}
            >
              <Icon type={iconType} />
              <Text
                variant='label_s_400'
                color='inherit'
                className='whitespace-pre-wrap break-words'
              >
                {t(`crowdin:moneyback.mission.learnable-kinds.${missionKind}`)}
              </Text>
            </li>
          )
        })}
      </ul>
      {criteriaItems.length > 0 && (
        <ul className='m-0 list-none space-y-1 p-0'>
          {criteriaItems[_activeIndex].descriptions.map(description => (
            <HighlightableText
              key={description}
              component='li'
              variant='body_m_400'
              className='whitespace-pre-wrap break-words'
              transProps={{
                values: criteriaItems[_activeIndex].values,
                components: ({ count, limit }, { Skeleton, Highlight }) => ({
                  HighlightKey: Number.isInteger(count) ? (
                    <Highlight />
                  ) : (
                    showSkeleton && (
                      <Skeleton className='inline-block h-5 w-10 align-text-bottom text-[0px]' />
                    )
                  ),
                  HighlightLimit: Number.isInteger(limit) ? (
                    <Highlight />
                  ) : (
                    showSkeleton && (
                      <Skeleton className='inline-block h-5 w-10 align-text-bottom text-[0px]' />
                    )
                  ),
                }),
              }}
            >
              {description}
            </HighlightableText>
          ))}
        </ul>
      )}
    </div>
  )
}

export { SuccessCriteria, type SuccessCriteriaProps }
