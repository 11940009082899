import type { Stage } from '../../env'
import Cookies from 'js-cookie'

type CookieAttributes = {
  expires?: number | Date
  path?: string
  domain?: string
  secure?: boolean
  sameSite?: 'Strict' | 'Lax' | 'None'
}

type SetCookieParams = {
  key: string
  value: string
} & Pick<CookieAttributes, 'expires' | 'domain'>

type ICookieStorage = {
  set: (params: SetCookieParams) => void
  get: <T>(
    key: string,
    isJson?: boolean,
    isStageKey?: boolean,
  ) => T extends undefined ? string | Record<string, string> | null : T
  del: (key: string, options?: CookieAttributes) => void
}

const createInstance = (stage: Stage): ICookieStorage => {
  const stagedKey = (key: string) => `${stage !== 'production' ? `${stage}-` : ''}${key}`

  return {
    set: ({ key, value, expires = 7, domain }) => {
      if (!key || !value) return
      Cookies.set(stagedKey(key), value, {
        expires,
        domain,
        sameSite: 'Lax',
      })
    },
    get: (key, isJson = false, isStageKey = true) => {
      const value = Cookies.get(isStageKey ? stagedKey(key) : key)
      if (!value) return null
      if (isJson) return JSON.parse(value)
      return value
    },
    del: (key, options = {}) => {
      Cookies.remove(stagedKey(key), options)
    },
  }
}

export default createInstance
