import snakecaseKeys from 'snakecase-keys'
import { UpdateManageMaterialsMoneybackMissionsPayload } from './types'

export * from './tag'

export const getManageMaterialsMoneybackMissions = ({ materialId }: { materialId: string }) => {
  return {
    method: 'get',
    url: `/v1/manage/materials/${materialId}/moneyback_missions`,
  }
}

export const updateManageMaterialsMoneyBackMissions = ({
  materialId,
  payloads,
}: {
  materialId: string
  payloads: UpdateManageMaterialsMoneybackMissionsPayload
}) => {
  return {
    method: 'PUT',
    url: `/v1/manage/materials/${materialId}/moneyback_missions`,
    data: { data: snakecaseKeys(payloads) },
  }
}
